import * as React from "react"

import Seo from "../components/seo"
import BlurredSection from "../components/BlurredSection"
import HomeHero from "../components/home/HomeHero"
import Story from "../components/home/story"
import Portfolio from "../components/home/portfolio"
import Quote from "../components/home/quote"
import OurExpertise from "../components/home/our_expertise"
import ContactLead from "../components/ContactLead"

const IndexPage = () => (
  <>
    <Seo title="Tajara Hospitality" />
    <BlurredSection>
      <HomeHero />
      <Story />
      <Portfolio />
      <Quote />
      <OurExpertise />
      <ContactLead />
    </BlurredSection>
  </>
)

export default IndexPage
