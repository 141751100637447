import React from "react"
import Section from "../Section"
import LineVector from "../LineVector"
import { Heading } from "../UI/Typography"
import { Wrapper, Grid, Tile } from "../UI/Grid"

const Quote = () => {
  return (
    <Section>
      <Wrapper>
        <Grid justifyContent="center">
          <Tile col={10} textAlign="center">
            <Heading fontSize={{ _: "h3", lg: "d4" }} mb={4} lineHeight={1.5}>
            Inspired by the vision of our clients, our comprehensive approach has helped design projects and built developments that create timeless value. We offer a wealth of market intelligence, global knowledge and a local understanding on the complexity of hospitality and property business.  With our clients’ best interest in mind, we aim to provide the best advice to optimize real estate value and investment returns —— that will benefit the next generation.
            </Heading>
            <LineVector height="12px" width="154px" />
          </Tile>
        </Grid>
      </Wrapper>
    </Section>
  )
}

export default Quote
