import React from "react"
import Hero from "../Hero"
import HeroImg from "../../images/hero_home.jpg"
import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import Title from "../Title"
import styled from "styled-components"
import LinedText from "../LinedText"
import { space } from "styled-system"
import ArrowCta from "../ArrowCta"

const HomeHero = () => {
  return (
    <Hero img={HeroImg}>
      <ContainerStyled>
        <Row>
          <Col col={10} xl={6}>
            <TitleStyled size="d4" lg="d3" mb={{ _: 10, lg: 20 }}>
              Transforming Landscapes
              <br />
              into{" "}
              <LinedText
                title="Inspiring Destinations"
                height="14px"
              ></LinedText>
            </TitleStyled>
            <ArrowCta
              to="/contact-us"
              title="Talk To Us"
              arrow="circle"
              align="center"
            />
          </Col>
        </Row>
      </ContainerStyled>
    </Hero>
  )
}

const TitleStyled = styled(Title)`
  line-height: 1;
  ${space};
`

const ContainerStyled = styled(Container)`
  z-index: 2;
  position: relative;
`

export default HomeHero
