import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Section from "../Section"
import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import ArrowCta from "../ArrowCta"
import Title from "../Title"
import Carousel from "../Carousel"
import styled from "styled-components"

import { BREAKPOINTS } from "../../utils/styled_config"

const OurExpertise = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrow: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }
  const services = useStaticQuery(graphql`
    query ServiceQuery {
      allServicesJson {
        edges {
          node {
            id
            name
            img_thumb {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  aspectRatio: 1.3
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Section>
      <Container>
        <Row align="flex-end" justify="space-between" mb={10}>
          <Col lg>
            <Title size="d4" lg="d2">
              Our Expertise
            </Title>
          </Col>
          <Col col="auto">
            <ArrowCta to="/services" title="Browse Services" />
          </Col>
        </Row>

        <SliderStyle settings={settings}>
          <Carousel settings={settings}>
            {services.allServicesJson.edges.map((s, idx) => (
              <ExpertiseEntry key={idx}>
                <GatsbyImage
                  image={getImage(s.node.img_thumb)}
                  alt={s.node.name}
                  layout="fixed"
                  height={180}
                />
                <Title size={4} lg={3}>
                  {s.node.name}
                </Title>
              </ExpertiseEntry>
            ))}
          </Carousel>
        </SliderStyle>
      </Container>
    </Section>
  )
}

const SliderStyle = styled.div`
  .slick-list {
    margin: 0 -15px;
  }
  .slick-slide > div {
    padding: 0 15px;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    .slick-slide:nth-of-type(even) > div {
      padding-top: 40px;
    }
  }
`

const ExpertiseEntry = styled.div`
  text-align: center;

  ${Title} {
    margin-top: 1rem;
  }
`

export default OurExpertise
