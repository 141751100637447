import React from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { BREAKPOINTS } from "../../utils/styled_config"
import { STAGGER, FADEINUP, IMAGE_REVEAL } from "../../utils/animation"

import Row from "../Row"
import Col from "../Col"
import Title from "../Title"
import LineVector from "../LineVector"
import Tag from "../Tag"
import ImgReveal from "../ImgReveal"
import ArrowCta from "../ArrowCta"
import ScaledImage from "../ScaledImage"
import theme from "../../utils/theme"

import { useServicesData } from "../../hooks/use-services-data"

const PortfolioRow = ({ name, projId, imgThumb, tags, onClick }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const services = useServicesData()

  const tagList = services.filter(i => tags.includes(i.service_id))
  return (
    <PortfolioRowStyled
      justify="space-between"
      align="center"
      as={motion.div}
      ref={ref}
      variants={STAGGER}
      initial="initial"
      animate={inView ? "animate" : "initial"}
    >
      <Col lg={8}>
        <ImgReveal variants={IMAGE_REVEAL}>
          <motion.div
            className="img-content"
            variants={IMAGE_REVEAL}
          ></motion.div>
          <Hover onClick={onClick}>
            <ScaledImage width={345} height={286}>
              <GatsbyImage image={getImage(imgThumb)} alt={name} />
            </ScaledImage>
          </Hover>
        </ImgReveal>
      </Col>
      <Col lg={3}>
        <Title as={motion.div} size={3} variants={FADEINUP}>
          {name}
        </Title>

        <motion.div variants={FADEINUP}>
          <LineVector height={6} width={154} />
        </motion.div>
        {tags && (
          <TagList className={`tag--order-${projId}`}>
            {tagList.map(t => (
              <motion.li variants={FADEINUP} key={t.id}>
                <Tag name={t.name} />
              </motion.li>
            ))}
          </TagList>
        )}
        <motion.div variants={FADEINUP}>
          <ArrowCta title="View Project" type="button" onClick={onClick} />
        </motion.div>
      </Col>
    </PortfolioRowStyled>
  )
}

const PortfolioRowStyled = styled(Row)`
  & + & {
    margin-top: 60px;
  }

  .line-vector {
    top: -12px;
    position: relative;
  }

  .gatsby-image-wrapper {
    margin-bottom: 12px;
  }
  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    .gatsby-image-wrapper {
      margin-bottom: 0;
    }
    & + & {
      margin-top: 80px;
    }

    &:nth-child(even) {
      div:first-child {
        order: 999999;
      }
    }
  }
`

const TagList = styled.ul`
  margin: 0 0 18px;

  li {
    display: inline-block;
  }

  li + li {
    margin-top: 0.25rem;
    margin-left: 0.5rem;
  }

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    margin: 6px 0 18px;

    li {
      display: block;
    }

    li + li {
      margin-left: 0;
    }
  }
  &.tag--order-1 {
    display: flex;
    flex-direction: column;
    li {
      &:last-of-type {
        order: 2;
        margin-top: 0.25rem;
      }
      &:first-of-type {
        order: 1;
      }
      &:nth-of-type(2) {
        order: 4;
      }
      &:nth-of-type(3) {
        order: 3;
      }
      &:nth-of-type(4) {
        order: 6;
      }
      &:nth-of-type(5) {
        order: 5;
      }
    }
  }
  &.tag--order-17 {
    display: flex;
    flex-direction: column;
    li {
      &:last-of-type {
        order: 5;
      }
      &:first-of-type {
        order: 1;
      }
      &:nth-of-type(2) {
        order: 2;
      }
      &:nth-of-type(3) {
        order: 4;
      }
      &:nth-of-type(4) {
        order: 3;
      }
    }
  }
`

const Hover = styled.div`
  .gatsby-image-wrapper {
    transition: ${theme.transition} !important;
  }
  &:hover {
    cursor: pointer;

    .gatsby-image-wrapper {
      transform: scale(1.2);
    }
  }
`
export default PortfolioRow
