import React from "react"
import Section from "../Section"
import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import Paragraph from "../Paragraph"
import ArrowCta from "../ArrowCta"
import { Heading } from "../UI/Typography"

const Story = () => {
  return (
    <Section>
      <Container>
        <Row>
          <Col lg={5}>
            <Heading fontSize={{ lg: 1 }} mb={4}>
              Our Story
            </Heading>
          </Col>
          <Col lg={7}>
            <Paragraph>
            We are travelers & builders traversing islands, cities and different terrains to transform spaces into well-planned hospitality developments.  With extensive expertise in developing various hospitality assets in most parts of the Philippines, we provide bespoke solutions to help our clients succeed in this multi-faceted industry.
            </Paragraph>
            <ArrowCta to="/our-story" title="Get To Know Us" />
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Story
