import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Container from "../Container"
import Row from "../Row"
import Col from "../Col"
import Title from "../Title"
import PortfolioRow from "./portfolio_row"
import ArrowCta from "../ArrowCta"
import PortfolioModal from "../portfolio/PortfolioModal"

const Portfolio = () => {
  const [projectDetails, setProjectDetails] = useState("")
  const [selectedProject, setSelectedProject] = useState()
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }

  const handleProjectClick = id => {
    setShow(true)
    setProjectDetails(id)
  }

  const projects = useStaticQuery(graphql`
    query PortfolioQuery {
      allProjectsJson(filter: { home_featured: { eq: true } }) {
        edges {
          node {
            id
            project_id
            name
            description
            location
            service_ids
            soon
            img_home {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
              }
            }
            slider_imgs {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setSelectedProject(
      projects.allProjectsJson.edges.find(i => i.node.name === projectDetails)
    )
  }, [projectDetails, projects.allProjectsJson.edges, selectedProject])
  return (
    <HomePortfolio>
      <Container className="portfolio">
        <Row align="flex-end" justify="space-between" mb={10}>
          <Col lg>
            <Title size="d4" lg="d2">
              Portfolio 
            </Title>
          </Col>
          <Col col="auto">
            <ArrowCta to="/portfolio" title="Explore Projects" />
          </Col>
        </Row>
        {projects &&
          projects.allProjectsJson.edges.map(p => (
            <PortfolioRow
              key={p.node.id}
              name={p.node.name}
              projId={p.node.project_id}
              imgThumb={p.node.img_home}
              tags={p.node.service_ids}
              onClick={() => handleProjectClick(p.node.name)}
            />
          ))}
        {selectedProject && (
          <PortfolioModal
            show={show}
            handleClose={handleClose}
            id={projectDetails}
            name={selectedProject.node.name}
            sliderImages={selectedProject.node.slider_imgs}
            location={selectedProject.node.location}
            description={selectedProject.node.description}
            tags={selectedProject.node.service_ids}
            soon={selectedProject.node.soon}
            projId={selectedProject.node.project_id}
          ></PortfolioModal>
        )}
      </Container>
    </HomePortfolio>
  )
}

const HomePortfolio = styled.div`
  padding: 80px 0;
  .portfolio {
    div {
      &:nth-of-type(3) {
        display: none;
      }
      &:nth-of-type(4) {
        flex-direction: row-reverse;
      }
      &:nth-of-type(5) {
        flex-direction: row-reverse;
      }
      &:nth-of-type(6) {
        flex-direction: row-reverse;
      }
    }
  }
`

export default Portfolio
